<template>
  <div>
    <!-- 头部导航 -->
    <nav-bar></nav-bar>
    <!-- 工具栏 -->
    <tool-box></tool-box>
    <div class="player-con">
      <div class="container">
        <div class="player">
          <div class="player-title">
            <svg-icon icon-class="banji" class="icon-title"></svg-icon>
            <span class="grade-title">
              {{ comboData.title ? comboData.title : "暂无课程标题" }}
            </span>
          </div>
          <div class="player-list">
            <!-- 播放界面 -->
            <div class="video" v-loading="playerLoading">
              <div class="video-mask" v-if="playerFlag">
                <img
                  src="@/assets/images/course/no-video.jpg"
                  alt=""
                  style=""
                />
              </div>
              <div v-if="!playerFlag" class="video-player">
                <!-- <div v-if='playerVid'>
		              <vue-player :player-vid='playerVid' vue-player>
		            </div> -->
                <div v-if="xgInfo.lessionSrc != ''">
                  <div v-if="playerVid && xgInfo.lessionSrc == '1'">
                    <vue-player
                      :player-vid="playerVid"
					  :flag="flag"
                      :video-info="videoInfo"
                      :grade-id="gradeid"
											:login-flag="loginFlag"
                    ></vue-player>
                  </div>
                  <div v-else>
										<xg-player 
											:xg-info="xgInfo"
											:video-info="videoInfo"
											:grade-id="gradeid"
											></xg-player>
										<div id="vlogo" v-if='vlogo'>
										  <img :src="vlogo" style="height:50px;" alt="">
										</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 播放列表 -->
            <div class="video-slide">
              <div class="slide-title">
                <h2 class="title">
                  {{ playerList.title ? playerList.title : "暂无课程标题" }}
                </h2>
                <div class="slide-item">
                  <span>讲师：</span>
                  <div
                    v-if="playerList.teacher && playerList.teacher.length > 0"
                  >
                    <span
                      class="teacher-list"
                      v-for="(item, index) in playerList.teacher"
                      :key="index"
                      >{{ item.name }}</span
                    >
                  </div>
                  <span v-else>暂无讲师</span>
                </div>
                <div class="slide-item">
                  共 :
                  {{ playerList.sections ? playerList.sections.length : 0 }} 讲
                </div>
              </div>
              <div class="slide-con">
                <ul
                  class="directory"
                  v-if="playerList.sections && playerList.sections.length > 0"
                >
                  <li
                    v-for="(item, index) in playerList.sections"
                    :key="index"
                    :class="activeSection == index ? 'sectionActive' : ''"
                    @click="playSection(item, index)"
                    class="dir-list"
                  >
                    <span class="title">{{ item.title }}</span>
                    <!-- 已经购买 -->
                    <div class="dir-list-style" v-if="buyFlag">
                      <div
                        v-if="activeSection == index"
                        class="list-style play"
                      >
                        <svg-icon
                          icon-class="play-icon"
                          class="dir-icon"
                        ></svg-icon>
                        <span>正在播放</span>
                      </div>
                    </div>
                    <!-- 未购买 -->
                    <div class="dir-list-style" v-else>
                      <div v-if="item.free == 1">
                        <div
                          v-if="activeSection == index"
                          class="list-style play"
                        >
                          <svg-icon
                            icon-class="play-icon"
                            class="dir-icon"
                          ></svg-icon>
                          <span>正在播放</span>
                        </div>
                        <div v-else class="list-style free">
                          <svg-icon
                            icon-class="free"
                            class="dir-icon"
                          ></svg-icon>
                          <span>免费试听</span>
                        </div>
                      </div>
                      <div v-else class="list-style nofree">
                        <svg-icon
                          icon-class="nofree"
                          class="dir-icon"
                        ></svg-icon>
                        <span>需要购买</span>
                      </div>
                    </div>
                  </li>
                </ul>
                <div v-else>
                  <el-empty description="暂无章节数据"></el-empty>
                </div>
              </div>
              <div class="slide-tools">
                <ul>
                  <li @click="coursePaper()">
                    <svg-icon
                      icon-class="linianzhenti"
                      class="icon-tools"
                    ></svg-icon>
                    <span>在线题库</span>
                  </li>
                  <li @click="courseEvaluation()">
                    <svg-icon
                      icon-class="shoucang"
                      class="icon-tools"
                    ></svg-icon>
                    <span>收藏课程</span>
                  </li>
                  <li @click="courseDownLoad()">
                    <svg-icon icon-class="xiazai" class="icon-tools"></svg-icon>
                    <span>下载讲义</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="combo-buy container" v-show="!buyFlag">
      <el-card class="box-card">
        <div class="buy-btn">
          <div class="price">
            ￥<span>{{ comboData.sale_price }}</span>
          </div>
          <el-button
            class="handdle-btn"
            type="danger"
            @click="toCreateOrder()"
            size="medium"
            >立即报名</el-button
          >
        </div>
      </el-card>
    </div>
    <!-- 套餐列表 -->
    <div class="grade-content container" v-if="!isGrade">
      <el-row :gutter="15">
        <el-col :span="18">
          <el-card class="box-card">
            <template #header>
              <div class="card-header">
                <span>课程内容</span>
              </div>
            </template>
            <div class="grade-list" v-if="gradeList.length > 0">
              <div
                class="combo-list"
                :key="index"
                v-for="(item, index) in gradeList"
              >
                <div class="list-title">{{ item.title }}</div>
                <ul class="list-content">
                  <li
                    :key="gindex"
                    :class="gitem.checked ? 'gradesActive' : ''"
                    @click="changeGrades(gitem)"
                    v-for="(gitem, gindex) in item.grades"
                  >
                    <span>{{ gitem.title }}</span>
                    <div class="listen">
                      <span v-show="!buyFlag">试听</span>
                      <svg-icon
                        icon-class="shipin"
                        class="icon-listen"
                      ></svg-icon>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="6">
          <div class="combo-relate">
            <div class="relate-title">
              <span>相关课程</span>
            </div>
            <div class="relate-content">
              <div
                class="relate-item"
                v-for="(item, index) in relateList"
                :key="index"
              >
                <comboclass-item :item="item"></comboclass-item>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 班级 -->
    <div v-else class="grade-content container">
      <el-row>
        <el-col :span="24">
          <el-card class="box-card">
            <template #header>
              <div class="card-header">
                <span>课程内容</span>
              </div>
            </template>
            <div class="grade-list" v-if="sectionsList.length > 0">
              <div class="combo-list">
                <ul class="list-content">
                  <li
                    :key="sindex"
                    @click="changeSection(sitem, sindex)"
                    :class="activeSection == sindex ? 'gradesActive' : ''"
                    v-for="(sitem, sindex) in sectionsList"
                  >
                    <span>{{ sitem.title }}</span>
                    <div class="listen">
                      <span v-show="!buyFlag">试听</span>
                      <svg-icon
                        icon-class="shipin"
                        class="icon-listen"
                      ></svg-icon>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="grade-list" v-else>
              <el-empty description="暂无章节数据"></el-empty>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <el-dialog title="下载提示" v-model="downloadDialogVisible" width="30%">
      <span v-if="downloadList.length > 1">
        <i class="el-icon-info" style="color: #f90"></i>
        配套讲义有新旧两个版本，请选择合适的版本下载！
      </span>
      <ul class="download-content">
        <li
          v-for="(downloadItem, downIndex) in downloadList"
          :key="downIndex"
          class="downloadListItem"
        >
          <span>{{ playerList.title }}</span>
          <span>{{ downloadItem.year }}版</span>
          <el-link :href="downloadItem.url" target="_blank" type="primary"
            >下载</el-link
          >
        </li>
      </ul>
      <template #footer>
        <el-button
          size="mini"
          type="primary"
          @click="downloadDialogVisible = false"
          >关闭</el-button
        >
      </template>
    </el-dialog>

    <!-- footer -->
    <web-footer></web-footer>
  </div>
</template>
<script>
import Player from 'xgplayer';
// import './.xgplayer/skin/index.js';
import NavBar from "@/components/page/NavBar";
import ToolBox from "@/components/toolbox";
import WebFooter from "@/components/page/WebFooter";
import {
  getComboGrade,
  getComboBySpec,
  isGou,
  isGouGrade,
  getDownload,
  collectionCombo,
  cancleCollection,
  getLessionSrc,
  getGradeSections,
  userPlayPlanUpload,
} from "@/api/course";
import {
  getSessionStorage,
  setSessionStorage,
  getLocalStorage,
  setLocalStorage,
	removeLocalStorage
} from "@/utils/auth";
import vuePlayer from "./components/VuePlayer";
import xgPlayer from "./components/XGPlayer";
import comboclassItem from "./components/ComboClassItem";
import { getProSpec } from "@/api/index";
import { getCourseHistory } from "@/api/user";
import sha1 from "js-sha1";


export default {
  name: "CoursePlayer",
  props: ["comboid", "gradeid", "sectionid"], //套餐，班级，章节
  data() {
    return {
      productSpecList: [],
      playerList: [], //播放列表
      comboData: {}, //套餐班级列表
      activeGrade: 0, //播放选中
      activeSection: "", //班级章节选中,
      playerVid: "",
	  flag:"",
      lessionId: "",
      lessionSrc: "", //为空则使用保利威播放器，否则使用常规播放器
		loginFlag: 0,
      playerFlag: false, //视频是否允许点击
      playerLoading: true,
      gradeList: [], //班级列表
      specid: "", //分类
      buyFlag: false, //是否购买
      isGrade: false, //是否是班级
      sectionsList: [], //班级章节
      relateList: [], //相关课程
      downloadList: [], //下载章节
      downloadDialogVisible: false,
      videoInfo: {}, //正在播放视频信息
      sectionsItem: {}, //正在播放的章节
      sectionHistory: {}, //历史记录章节
      record: {
        time: 0,
        timer: "",
      }, //记录播放时长
			xgInfo: {
				lessionSrc: '',//视频播放地址
			},	//西瓜视频数据信息
    };
  },
  components: {
    NavBar,
    ToolBox,
    WebFooter,
    vuePlayer,
		xgPlayer,
    comboclassItem,
  },
  watch: {
    activeSection(newval) {
      let lession_id = this.playerList.sections[newval].lessons_id;
      if (lession_id == null || lession_id == 0) {
        this.xgInfo.lessionSrc = "1";
      } else {
        getLessionSrc({
          lesson_id: lession_id,
          charge_type: this.buyFlag ? "pay" : "test",
        }).then((res) => {
          if (res.code == 200) {
						let xgInfo = {};
            xgInfo.lessionSrc = res.data.video.video_path;
						xgInfo.picture = res.data.lesson.picture
						this.xgInfo = xgInfo	//重新赋值才能监听到数据变化
          } else {
            this.xgInfo.lessionSrc = "1";
          }
        });
      }
    },
    playerList(newval) {
      //套餐，切换班级
      if (newval.sections && newval.sections.length > 0) {
        let sectionIndex = newval.sections.map((item) => item.free).indexOf(1);
        //12/30改
        newval.sections.find((item, index) => {
          //播放观看记录章节
          if (item.id == this.sectionid) {
            sectionIndex = index;
            return true;
          }
        });
        if (sectionIndex == -1) {
          //都是需要购买
          this.activeSection = 0;
          this.playerVid = newval.sections[this.activeSection].video;
          this.flag = newval.sections[this.activeSection].flag;
          this.videoInfo = newval.sections[this.activeSection];
          this.playerFlag = true; //视频不允许点击
        } else {
          this.activeSection = sectionIndex;
          this.playerVid = newval.sections[this.activeSection].video;
          this.videoInfo = newval.sections[this.activeSection];
		  this.flag = newval.sections[this.activeSection].flag;
          this.playerFlag = false;
        }
        this.playerLoading = false;
      } else {
        this.playerVid = "";
        this.playerLoading = true;
      }
      // setLocalStorage("sectionsItem", this.videoInfo); //存放上一章节信息
    },
  },
  created() {
		if(getLocalStorage("courseDetailFlash")){
			removeLocalStorage("courseDetailFlash");
		}else{
			setLocalStorage("courseDetailFlash",1)
			window.location.reload(true);
		}
    if (this.comboid == 0) {
      //班级
      this.isGrade = true;
      // if(this.sectionid==0){//无播放记录
      getGradeSections({
        //获取班级章节
        id: this.gradeid,
      }).then((res) => {
        if (res.code == 1) {
          this.comboData = res.data;
          //播放列表
          this.playerList = res.data;
          //班级内容，章节
          this.sectionsList = res.data.sections;
          this.videoInfo = this.sectionsList[0];
          //判断班级是否购买
          //套餐是否购买
          if (getLocalStorage("token")) {
            //判断当期那套餐用户是否购买
            isGouGrade({
              grade_id: res.data.id,
            }).then((res) => {
              if (res.code == 1) {
                this.buyFlag = true;
              } else {
                this.buyFlag = false;
              }
            });
						this.loginFlag = 1;
          } else {
            this.buyFlag = false;
						this.loginFlag = 0;
          }
        }
      });
      // }else{//有播放记录

      // }
    } else {
      //套餐
      getProSpec().then((res) => {
        //行业分类，考试专题
        if (res.code == 1) {
          this.productSpecList = res.data;
        }
      });
      this.isGrade = false;
      // if(this.sectionid==0){//章节id
      getComboGrade({
        id: this.comboid,
      }).then((res) => {
        if (res.code == 1) {
          //套餐信息
          this.comboData = res.data;
          //班级列表
          this.gradeList = res.data.course;
          //班级选中
          if (this.gradeList) {
            this.gradeList.map((item, index) => {
              item.grades.map((gitem, gindex) => {
                if (gitem.id == this.gradeid) {
                  gitem.checked = true;
                  this.playerList = gitem;
                  this.videoInfo = gitem.sections[0];
                } else {
                  gitem.checked = false;
                }
              });
            });
          }
          //套餐是否购买
          if (getLocalStorage("token")) {
            //判断当期那套餐用户是否购买
            isGou({
              product_id: res.data.id,
              type: 1,
            }).then((res) => {
              if (res.code == 1) {
                this.buyFlag = true;
              } else {
                this.buyFlag = false;
              }
            });
						this.loginFlag = 1;
          } else {
            this.buyFlag = false;
						this.loginFlag = 0;
          }
          //套餐分类
          this.specid = res.data.spec_id;
          //相关推荐
          getComboBySpec({
            spec_id: res.data.spec_id,
          }).then((res) => {
            if (res.code == 1) {
              this.relateList = res.data.comboclass;
            }
          });
          //当前播放班级
          if (this.gradeList) {
            this.gradeList.map((item, index) => {
              item.grades.map((gitem, gindex) => {
                if (gitem.id == this.gradeid) {
                  gitem.checked = true;
                } else {
                  gitem.checked = false;
                }
              });
            });
          }
        }
      });
      // }else{

      // }
    }
  },
  methods: {
    resetGradesList() {
      //重置班级选中状态
      this.gradeList.map((item, index) => {
        item.grades.map((gitem, gindex) => {
          gitem.checked = false;
        });
      });
    },
    changeGrades(gitem) {
			setLocalStorage("gradeId",this.gradeid)
      //切换套餐班级
      let gradeid = gitem.id;
      this.playerList = gitem;
      this.resetGradesList();
      gitem.checked = true;
      getCourseHistory({ grade_id: gradeid }).then((res) => {
        let haveHistoryArr = res.data.list.filter((item) => {
          return item.history != null;
        });
        if (haveHistoryArr.length > 0) {
          setLocalStorage(
            "sectionHistory",
            haveHistoryArr[haveHistoryArr.length - 1].history
          );
          let sectionId =
            haveHistoryArr[haveHistoryArr.length - 1].history.section_id;
          this.$router.push(
            "/courseplayer/" + this.comboid + "/" + gradeid + "/" + sectionId
          );
        } else {
          // 没有观看记录，就从第一节开始
          this.$router.push(
            "/courseplayer/" + this.comboid + "/" + gradeid + "/0"
          );
        }
      });

      // this.$router.push({
      //    path:'/courseplayer/'+ this.comboid+'/'+ gradeid+'/0'
      // });
    },
    changeSection(item, index) {
      //切换班级章节
      setLocalStorage("sectionsItem", this.videoInfo); //存放上一章节信息
      if (this.buyFlag) {
        this.activeSection = index;
        this.playerVid = item.video;
        this.videoInfo = item;
      } else {
        if (item.free == 1) {
          this.activeSection = index;
          this.playerVid = item.video;
          this.videoInfo = item;
        } else {
          this.$notify.error({
            title: "需要购买",
            message: "该课时不支持试听",
            duration: 1500,
            showClose: false,
          });
          return;
        }
      }
    },
    playSection(item, index) {
      // 先将上一章视频记录提交
      if (this.$refs.video) {
         this.$refs.video.pause()
      }
      //点击播放列表，切换章节
      setLocalStorage("sectionsItem", this.videoInfo); //存放上一章节信息
      if (this.buyFlag) {
        //已经购买
        this.activeSection = index;
        if (!item.video) {
          this.$message("暂无视频资源");
        } else {
          this.playerVid = item.video;
          this.videoInfo = item;
        }
      } else {
        //未购买
        if (item.free == 1) {
          //允许播放
          this.activeSection = index;
          if (!item.video) {
            this.$message("暂无视频资源");
          } else {
            this.playerVid = item.video;
            this.videoInfo = item;
          }
        } else {
          this.$notify.error({
            title: "需要购买",
            message: "该课时不支持试听",
            duration: 1500,
            showClose: false,
          });
          return;
        }
      }
    },
    toCreateOrder() {
      //创建订单
      if (getLocalStorage("token")) {
        let comboid = this.comboData.id;
        setSessionStorage("createOrderInfo", this.comboData);
        this.$router.push({
          path: "/order/" + comboid + "/1",
        });
      } else {
        this.$notify.error({
          title: "未登录",
          message: "请先登录",
        });
        return;
      }
    },
    coursePaper() {
      //在线题库
      if (getLocalStorage("token")) {
        if (this.isGrade) {
          this.$notify.error({
            title: "提示",
            message: "暂无题库",
          });
          return;
        } else {
          let specid = this.comboData.spec_id;
          this.productSpecList.map((item, index) => {
            item.children.map((citem, cindex) => {
              if (citem.id == specid) {
                setSessionStorage("examListParams", {
                  specId: item.id,
                  specChildId: specid,
                  type: 7,
                });
                this.$router.push("/examlist");
              }
            });
          });
        }
      } else {
        this.$notify.error({
          title: "未登录",
          message: "请先登录",
        });
        return;
      }
    },
    courseEvaluation() {
      //收藏课程
      if (getLocalStorage("token")) {
        if (this.isGrade) {
          this.$notify.error({
            title: "提示",
            message: "收藏失败",
          });
          return;
        } else {
          collectionCombo({
            combo_id: this.comboData.id,
          }).then((res) => {
            if (res.code == 1) {
              this.$notify({
                title: "成功",
                message: "已收藏",
                type: "success",
              });
            }
            if (res.code == 3) {
              this.$notify({
                title: "已收藏",
                message: "请勿重复收藏",
                type: "success",
              });
            }
          });
        }
      } else {
        this.$notify.error({
          title: "未登录",
          message: "请先登录",
        });
        return;
      }
    },
    courseDownLoad() {
      //下载章节
      if (getLocalStorage("token")) {
        if (this.buyFlag) {
          let gradeId = this.playerList.id;
          getDownload({ grade_id: gradeId }).then((res) => {
            //如果有多个版本的，则取出来前两个版本，让用户选择下载当前版本还是旧版本
            if (res.data.length == 0) {
              this.$notify.error({
                title: "提示",
                message: "暂无讲义",
              });
            } else if (res.data.length > 0) {
              this.downloadList = res.data;
              this.downloadDialogVisible = true;
            }
          });
        } else {
          this.$notify.error({
            title: "需要购买",
            message: "该课时不支持试听",
            duration: 1500,
            showClose: false,
          });
          return;
        }
      } else {
        this.$notify.error({
          title: "未登录",
          message: "请先登录",
        });
        return;
      }
    },
    doInitPlayer() {
      window.onbeforeunload = e => {
        this.$refs.video.pause()
      }
    },

  },
};
</script>

<style scoped lang="scss">
.player-con {
  background: #23303e;
  padding: 20px 0px;
  .player {
    .player-title {
      padding: 10px 0px;
      font-size: 18px;
      color: #fff;
      display: flex;
      flex-direction: row;
      align-items: center;
      .icon-title {
        margin-right: 5px;
      }
    }
    .player-list {
      width: 100%;
      height: 550px;
      display: flex;
      flex-direction: row;
      box-sizing: border-box;
      border: 1px solid #334559;
      overflow: hidden;
      .video {
        flex: 1;
        height: 100%;
        margin-right: 0px;
        .video-mask {
          height: 100%;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
        .video-player {
					position: relative;
					padding: 0;
          overflow: hidden;
          border-right: 1px solid #334559;
        }
      }
      .video-slide {
        width: 300px;
        background-color: #23303e;
        display: flex;
        flex-direction: column;
        border-left: 1px solid #334559;
        color: #fff;
        .slide-title {
          padding: 5px 10px;
          height: 95px;
          color: #fff;
          .title {
            color: #fff;
            font-size: 16px;
            height: 30px;
            line-height: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .slide-item {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 10px 0px;
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            .teacher-list {
              padding: 0px 5px;
              border-right: 1px solid #fff;
            }
            .teacher-list:last-child {
              border: none;
            }
          }
        }
        .slide-con {
          flex: 1;
          display: flex;
          flex-direction: column;
          padding: 0px 8px;
          overflow: auto;
          background-color: #334559;
          .dir-list {
            display: flex;
            flex-direction: column;
            cursor: pointer;
            padding: 0px 8px;
            margin-top: 10px;
            border: 1px solid #23303e;
            .title {
              padding: 10px 0px;
              font-size: 12px;
            }
            .dir-list-style {
              padding: 5px 0px;
              font-size: 12px;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              .list-style {
                font-size: 12px;
              }
              .dir-icon {
                margin-right: 5px;
              }
              .free {
                color: $free-color;
              }
              .nofree {
                color: $need-buy-color;
              }
              .play {
                color: $play-color;
              }
            }
          }
          .dir-list:hover {
            border: 1px solid #fff;
          }
          .dir-list.sectionActive {
            border: 1px solid #fff;
          }
        }
        // 定义滚动条高宽及背景
        // 高宽分别对应横竖滚动条的尺寸
        .slide-con::-webkit-scrollbar {
          width: 8px;
          height: 8px;
        }
        // 定义滚动条轨道 内阴影+圆角
        .slide-con::-webkit-scrollbar-track {
          border-radius: 10px;
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          
        }
        // 定义滑块 内阴影+圆角
        .slide-con::-webkit-scrollbar-thumb {
          background-color: #909399;
          border-radius: 10px;
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }
        .slide-tools {
          ul {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            li {
              height: 55px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              flex: 1;
              font-size: 12px;
              cursor: pointer;
              .icon-tools {
                margin-bottom: 3px;
                font-size: 18px;
              }
            }
            li:hover {
              color: $theme-color;
            }
          }
        }
      }
    }
  }
}
.combo-buy {
  margin: 15px auto;
  .buy-btn {
    padding: 20px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    .price {
      color: $price-color;
      margin-right: 50px;
      span {
        font-size: 20px;
      }
    }
  }
}
//班级列表
.grade-content {
  margin-top: 15px;
  .card-header {
    padding: 0px 20px;
    height: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      font-size: 20px;
      font-weight: bold;
      padding-left: 10px;
      border-left: 3px solid $theme-color;
    }
  }
  .grade-list {
    padding: 10px 20px;
    .combo-list {
      .list-title {
        font-size: 18px;
        font-weight: bold;
        padding: 10px 0px;
      }
      .list-content {
        li {
          padding: 10px 10px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 5px;
          .listen {
            font-size: 12px;
            color: $theme-color;
            .icon-listen {
              font-size: 14px;
            }
          }
        }
        li:hover {
          color: $theme-color;
          background-color: #eee;
        }
        li.gradesActive {
          color: $theme-color;
          background-color: #eee;
        }
      }
    }
  }
}
.combo-relate {
  .relate-title {
    height: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      font-size: 20px;
      font-weight: bold;
      padding-left: 10px;
      border-left: 3px solid $theme-color;
    }
  }
  .relate-content {
    .relate-item {
      margin-bottom: 15px;
    }
  }
}
.downloadListItem {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 30px;
  border-bottom: solid 1px #eee;
}
#vlogo{
	  text-align: right;
	  position:absolute;
	  right:20px;
	  top:45px;
	  z-index:1000;
	  width:100%;
	}
</style>
<style>
.pv-start-time {
  display: none !important;
}
</style>