<template>
  <div id="container">
    <div id="vlogo" v-if="vlogo">
      <img :src="vlogo" style="height: 50px" alt="" />
    </div>
    <div id="player"></div>
    <!-- {{ realPlan }} -->
  </div>
</template>

<script>
import {
  getPlaysafe,
  sendVideoInfo,
  getPlaysafeTokenMoblie,
  userPlayPlanUpload,
} from "@/api/course";
import {
  setLocalStorage,
  getLocalStorage,
  removeLocalStorage,
} from "@/utils/auth";
import { getCourseHistory } from "@/api/user";

export default {
  props: ["playerVid", "videoInfo", "gradeId","loginFlag",'flag'],
  name: "vuePlayer",
  data() {
    return {
      vodPlayerJs: "https://player.polyv.net/script/player.js",
      sitename: "",
      vlogo: "",
			record: {
				timer: '',
				time: 0
			},
			lookTime: 0,
			lookTimeTimer: null
    };
  },
  mounted() {
		this.loadPlayerScript(this.loadPlayer);
		let logo = getLocalStorage("siteInfo").pic.vLogo;
		this.vlogo = logo.replace("\\", "/");
		this.sitename = getLocalStorage("siteInfo").info.sitename;
		window.addEventListener('beforeunload', e => {
			this.beforeunloadFn(e)
		})
		this.lookTimeTimer = setInterval(()=>{
			this.lookTime++;
		},950)
  },
  watch: {
    playerVid (newVal){//视频切换
			new Promise((resolve, reject) =>{
				this.pauseRecord(this.player.j2s_getCurrentTime(),this.lookTime)//切换视频，上传记录
				resolve()
			}).then(res =>{
				this.player.changeVid({vid: newVal})
			})
		},
		gradeId(){//切换班级
			this.pauseRecord()//切换视频，上传记录
		},
  },
  methods: {
    getSectionHistory() {//查询是否存在历史记录
    	return new Promise((resolve, reject) => {
    		var sectionsHistory = null;
				const gradeId = getLocalStorage("gradeId") || this.gradeId	//有gradeid说明切换班级了
				if(this.loginFlag){
					getCourseHistory({ grade_id: gradeId }).then((res) => {//查询章节历史记录
						var videoInfo = null;
						sectionsHistory = res.data.list
					    .filter((item) => {
					      return item.history != null;
					    })
					    .filter((item) => {
								var videoInfo = getLocalStorage("sectionsItem") || this.videoInfo;//切换前视频信息
					      return item.id == videoInfo.id;
					    });
					  if (
					    sectionsHistory != null &&
					    sectionsHistory != undefined &&
					    sectionsHistory.length > 0
					  ) {
					    sectionsHistory = sectionsHistory[0].history;
					  }else {
							sectionsHistory = {};
							sectionsHistory.looked = 1;
							sectionsHistory.look_time = 1;
							sectionsHistory.end = 1;
							sectionsHistory.duration = this.player.j2s_getDuration()
							let minute = Math.floor(
							  Math.floor(parseInt(this.player.j2s_getDuration())) / 60
							);
							let second = Math.floor(parseInt(this.player.j2s_getDuration())) % 60;
							minute = minute > 9 ? minute : ("0" + minute);
							second = second > 9 ? second : ("0" + second);
							sectionsHistory.duration = minute + ":" + second;
						}
						resolve(sectionsHistory)
					});
				}else {
					sectionsHistory = {};
					sectionsHistory.looked = 1;
					sectionsHistory.look_time = 1;
					sectionsHistory.end = 1;
					sectionsHistory.duration = this.player.j2s_getDuration()
					let minute = Math.floor(
					  Math.floor(parseInt(this.player.j2s_getDuration())) / 60
					);
					let second = Math.floor(parseInt(this.player.j2s_getDuration())) % 60;
					sectionsHistory.duration = minute>9 ? minute : ("0" + minute) + ":" + second > 9 ? second : ("0" + second);
					resolve(sectionsHistory)
				}
    	})
    },
		loadPlayer() {//初始化保利威播放器
		  new Promise((resolve, reject)=>{
		  	var sign = "";
			console.log(this.flag)
			let flag = this.flag
		  	const polyvPlayer = window.polyvPlayer;
		  	this.player = polyvPlayer({
		  	  wrap: "#player",
		  	  height: 550,
		  	  pictureInPicture: true,
		  	  keyboardSeekTime: 5000,
		  	  ban_preview_video: true,
		  	  rightMenu: [{ rightName: this.sitename }],
		  	  vid: this.playerVid,
			  
		  	  playsafe: function (vid,next) {
		  	    getPlaysafe({
		  	      "vid": vid,
				  "flag":flag,
		  	    }).then((res) => {
		  	      if (res.code == 1) {
		  	        next(res.data.token);
		  	      }
		  	    });
		  	  },
		  	});
		  	resolve()
		  }).then(()=>{
				//------------------ 老版本循环执行保存（现关闭浏览器处理已解决） -------------------------
				// this.timer = setInterval(()=>{
				// 	this.pauseRecord()
				// },3000)
			})
			this.playerListeners();
		},
		playerListeners() {//播放器事件监听
			if(this.loginFlag){
				this.player.on("s2j_onPlayerInitOver",() => {//播放器初始化完毕时触发
					this.player.j2s_resumeVideo()//恢复播放当前视频
				});
				
				// this.player.on("s2j_onPlayStart",()=>{//首次播放事件
				// 	this.getSectionHistory().then(secHistory => {//取出播放历史后跳转到指定位置，并清除缓存
				// 		if (secHistory) {//有历史记录就跳转到历史记录位置
				// 			try{
				// 				this.player.j2s_seekVideo(Math.round(secHistory?.end || 0)); //跳转到某个时刻播放
				// 			}catch(e){
				// 			}
				// 		}
				// 	})
				// });
				this.player.on("s2j_onVideoPlay",()=>{//开始播放事件
					this.playingRecord();//开始播放 记录时间
				});
				this.player.on("s2j_onVideoPause",()=>{//视频暂停
					this.pauseRecord();
				});
			}
		},
		playingRecord() {
			//播放-记录数据
			this.getSectionHistory().then(secHistory => {
				this.timeRecord(secHistory)
			});
		},
		pauseRecord(time,lookTime) {//暂停->播放记录
			if(lookTime){
				clearInterval(this.lookTimeTimer)
			}
			if(this.loginFlag){
				clearInterval(this.record.timer)//播放停止，清除定时器，暂停播放-上传数据
				time = this.player.j2s_getCurrentTime() || time
				const recordTime = this.record.time;
				this.record.time = 0;//记录后清空本次真实观看时间
				if(time <= 0) {
					return
				}else {
					this.getSectionHistory().then((secHistory) => {
						this.uploadPlan(secHistory,recordTime,time,lookTime)//保存播放进度接口
					})
				}
			}
		},
		timeRecord(sectionsHistory) {//真实时间记录 looked
			this.record.timer = setInterval(() => {
				//当前时间 >= 历史looked
				if(this.$route.fullPath.indexOf("courseplayer") > -1){
					if (this.player?.j2s_getCurrentTime() && this.player.j2s_getCurrentTime() >= (sectionsHistory.looked ?? 0)) {
						this.record.time++;
					}
				}
			}, 1000);
		},
		uploadPlan(secHistory,recordTime,time,lookTime) {//上传播放进度
			let currentTime = 0;
			var videoInfo = null;
			if(getLocalStorage("sectionsItem")){//有缓存 说明用户点击了切换章节/切换班级
				videoInfo = getLocalStorage("sectionsItem");
				currentTime = Math.floor(time) + recordTime + "";
			}else {
				videoInfo = this.videoInfo;
				currentTime = Math.floor(time) + "";
			}
			lookTime = lookTime || 0;
			const gradeId = getLocalStorage("gradeId") || this.gradeId
			removeLocalStorage("sectionsItem");
			removeLocalStorage("gradeId");
			userPlayPlanUpload({
				section_id: videoInfo.id,
				grade_id: this.gradeId,
				end: currentTime,
				duration: secHistory.duration,
				looked: (secHistory.looked ?? 0) + recordTime,
				look_time: secHistory.look_time + lookTime,//问题：lookerd小于后台时间lookTime也更新不了
			}).then((res) => {
				if (res.code == 1) {
					// console.log("进度保存成功！");
				}
			});
		},
		loadPlayerScript(callback) {//初始化保利威播放器
		  if (!window.polyvPlayer) {
		    const myScript = document.createElement("script");
		    myScript.setAttribute("src", this.vodPlayerJs);
		    myScript.onload = callback;
		    document.body.appendChild(myScript);
		  } else {
		    callback();
		  }
		},
		beforeunloadFn (e) {//关闭窗口事件
			this.pauseRecord(null,this.lookTime);
			// 这个事件只有在鼠标真正和浏览器有了交互，再刷新或者关闭时才会触发, 浏览器事件会弹框确认用户是否要离开页面
			e = e || window.event
			if (e) {
				e.returnValue = '关闭提示'
				this.player.j2s_pauseVideo()//取消关闭，暂停播放
				this.lookTimeTimer = setInterval(()=>{
					this.lookTime++;
				},990)
			}
			return '关闭提示'
		},
		beforeunloadHandler(e) {
			clearInterval(this.lookTimeTimer);
		},
  },
	beforeUnmount() {//组件销毁前
		if (this.player) {
			this.player.destroy();
		}
		window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
	},
  unmounted() {//组件销毁
    // if (this.player) {
    //   this.player.destroy();
    // }
  },
};
</script>
<style scoped="scoped" lang="scss">
#container {
  position: relative;
  padding: 0;
}
#vlogo {
  text-align: right;
  position: absolute;
  right: 20px;
  top: 45px;
  z-index: 1000;
  width: 100%;
}
</style>

