<template>
  <div id="container">
    <div id="vlogo" v-if="vlogo">
      <img :src="vlogo" style="height: 50px" alt="" />
    </div>
    <div id="player"></div>
  </div>
</template>

<script>
import Player from 'xgplayer';
import {
  getPlaysafe,
  sendVideoInfo,
  getPlaysafeTokenMoblie,
  userPlayPlanUpload,
} from "@/api/course";
import {
  setLocalStorage,
  getLocalStorage,
  removeLocalStorage,
} from "@/utils/auth";
import { getCourseHistory } from "@/api/user";

export default {
  props: ["xgInfo","gradeId","videoInfo","loginFlag"],
  name: "xgPlayer",
  data() {
    return {
      vodPlayerJs: "https://player.polyv.net/script/player.js",
      sitename: "",
      vlogo: "",
      schedule: {},
			record: {
				time: 0,
				timer: ''
			},
			timer: {}
    };
  },
  mounted() {
		this.getSectionHistory().then((secHistory) => {
			this.loadPlayer(secHistory);
		})
		let logo = getLocalStorage("siteInfo").pic.vLogo;
		this.vlogo = logo.replace("\\", "/");
		this.sitename = getLocalStorage("siteInfo").info.sitename;
  },
	beforeUnmount(){//组件销毁  只能在返回时候，切换路由的时候能用，刷新、关闭标签页和关闭浏览器不能用  所以需要定时器
		clearInterval(this.timer)
		this.pauseRecord()//关闭视频，上传记录		问题：获取不到视频时间
	},
  watch: {
		xgInfo(newval) {//切换视频
			this.player.src = newval.lessionSrc		//切换视频地址
			this.pauseRecord(this.player.currentTime)//切换视频，上传记录
		},
		gradeId(){//切换班级
			this.pauseRecord()//切换视频，上传记录
		},
  },
  methods: {
    loadPlayer(secHistory) {
			new Promise(()=>{
				this.player = new Player({
				  id: 'player',
				  url: this.xgInfo.lessionSrc,
					height: 550,
					width: 895,
					videoInit: true,
					lastPlayTime: secHistory?.end || 0, //视频起播时间（单位：秒）
					// lastPlayTimeHideDelay: 5 //提示文字展示时长（单位：秒）
				});
				if(this.loginFlag){
					this.playerListeners();	//监听视频播放
				}
			}).then(()=>{
				if(this.loginFlag){
					this.timer = setInterval(()=>{
						this.pauseRecord("setInterval")
					},3000)
				}
			})
    },
		playerListeners() {//播放器事件监听
			this.player.on("play",(e)=>{
				this.playingRecord()
			}).on("pause",(e)=>{
				this.pauseRecord()
			}).on("ended",(e)=>{
				this.pauseRecord()
			})
		},
		pauseRecord(time) {//暂停-记录
			if(this.loginFlag){
				clearInterval(this.record.timer)//播放停止，清除定时器，暂停播放-上传数据
				const recordTime = this.record.time;
				this.record.time = 0;//记录后清空本次真实观看时间
				var time = this.player.currentTime || time
				if(time <= 0) {
					return
				}else {
					this.getSectionHistory().then((secHistory) => {
						this.uploadPlan(secHistory,recordTime,time)//保存播放进度接口
					})
				}
			}
		},
		playingRecord() {//播放-记录
		  //播放-记录数据
		  this.record.time = 0; //记录看了实际时间（秒）
			this.getSectionHistory().then(secHistory => {
				this.timeRecord(secHistory)
			})
		},
		uploadPlan(secHistory,recordTime,time) {//上传播放进度
			let currentTime = 0;
			var videoInfo = null;
			if(getLocalStorage("sectionsItem")){//有缓存 说明用户点击了切换章节/切换班级
				videoInfo = getLocalStorage("sectionsItem");
				currentTime = Math.floor(time) + recordTime + "";
			}else {
				videoInfo = this.videoInfo;
				currentTime = Math.floor(time) + "";
			}
			const gradeId = getLocalStorage("gradeId") || this.gradeId
			removeLocalStorage("sectionsItem");
			removeLocalStorage("gradeId");
			userPlayPlanUpload({
				section_id: videoInfo.id,
				grade_id: this.gradeId,
				end: currentTime,
				duration: secHistory.duration,
				looked: (secHistory.looked ?? 0) + recordTime,
			}).then((res) => {
				if (res.code == 1) {
					console.log("进度保存成功！");
				}
			});
		},
		getSectionHistory() {//查询是否存在历史记录
			return new Promise((resolve, reject) => {
				var sectionsHistory = null;
				if(this.loginFlag){
					const gradeId = getLocalStorage("gradeId") || this.gradeId
					getCourseHistory({ grade_id: this.gradeId }).then((res) => {//查询章节历史记录
						if(res.code == 1){
							sectionsHistory = res.data.list
								.filter((item) => {
									return item.history != null;
								})
								.filter((item) => {
									var videoInfo = getLocalStorage("sectionsItem") || this.videoInfo;
									return item.id == videoInfo.id;
								});
							if (
								sectionsHistory != null &&
								sectionsHistory != undefined &&
								sectionsHistory.length > 0
							) {
								sectionsHistory = sectionsHistory[0].history;
							}else {
								sectionsHistory = {};
								sectionsHistory.looked = 0;
								let minute = Math.floor(
									Math.floor(parseInt(this.videoInfo.duration)) / 60
								);
								let second = Math.floor(parseInt(this.videoInfo.duration)) % 60;
								sectionsHistory.duration = minute + ":" + second;
							}
							resolve(sectionsHistory)
						}
					});
				}else{
					sectionsHistory = {};
					sectionsHistory.looked = 0;
					resolve(sectionsHistory)
				}
			})
		},
		timeRecord(sectionsHistory) {
			this.record.timer = setInterval(() => {
				if (this.player.currentTime >= (sectionsHistory.looked ?? 0)) {
					this.record.time++;
				}
			}, 1000);
		},
  },
  unmounted() {
    if (this.player) {
      this.player.destroy();
    }
  },
};
</script>
<style scoped="scoped" lang="scss">
#container {
  position: relative;
  padding: 0;
}
#vlogo {
  text-align: right;
  position: absolute;
  right: 20px;
  top: 45px;
  z-index: 1000;
  width: 100%;
}
</style>

