import request from '@/utils/request'
import qs from 'qs';

/**
 * 查询网站信息
 * @returns 
 */
export function getBanner(data) {//读取轮播图
  return request({
    url: '/index/banner',
    method: 'post',
    data:qs.stringify(data)
  })
}

export function getMenus(data) {//读取菜单
  return request({
    url: '/index/menus',
    method: 'post',
    data:qs.stringify(data)
  })
}

export function getProSpec(data) {//行业分类
  return request({
    url: '/product/spec',
    method: 'post',
    data:qs.stringify(data)
  })
}


export function getComboBySpec(data) {//按二级科目查询套餐，新闻，题库
  return request({
    url: '/product/getComboBySpec',
    method: 'post',
    data:qs.stringify(data)
  })
}


export function getAdvertSpec(data) {//读取广告
  return request({
    url: '/index/advertSpec',
    method: 'post',
    data:qs.stringify(data)
  })
}

export function getAdvertQQ(data) {//读取广告qq
  return request({
    url: '/index/qq',
    method: 'post',
    data:qs.stringify(data)
  })
}

export function getAdvertApp(data) {//读取广告app
  return request({
    url: '/index/app',
    method: 'post',
    data:qs.stringify(data)
  })
}
